/**
 * Created by zhengyf on 2018.2.7.
 */

;(function () {

    angular
        .module('app.core')
        .component('customerServicesComponent', {
            template:'<div class="ds-widget-component services-module"><div class="cn-new-module"><p class="se-title">客户服务</p><div class="se-content"><div class="se-box"><p><a href="http://service.alibaba.com/supplier/home.htm?spm=a2747.manage.left_menu.176.6e451298VtlHdr&amp;tracelog=zuocema" target="_blank" class="J-domdot-item se-entrance">进入服务中心</a></p><p><a href="http://weibo.com/alibabaservice?spm=a2747.manage.left_menu.177.6e451298VtlHdr" target="_blank" class="J-domdot-item se-weibo">关注客户中心微博</a></p></div><div class="se-box top-border"><div class="person-info"><div class="info-item"><p>我的客户经理</p><p>张三</p><p><a href="javascript:;">评价ta</a></p><img src="images/product-manage/default-avatar.png"></div><div class="info-item"><p>手机：12345678900</p><p><span>邮箱：</span> <a href="javascript:;">zhangsan@gdcbectp.com</a></p></div></div></div></div></div></div>',
            bindings: {

            },
            controllerAs: 'vm',
            controller: CustomerServicesCtrl
        });

    function CustomerServicesCtrl() {
        this.$onInit = onInit;

        function onInit() {
            console.log('[customerServicesComponent init: ]');
        }
    }


})();

