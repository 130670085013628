/**
 * Created by liwenjun on 2017/8/18.
 */
angular.module("DSSXAPP", ['app.core'])

    .controller("indexCtrl", ['$scope', '$timeout', 'request', '$filter', 'event', '$q', function ($scope, $timeout, request, $filter, event, $q) {
        $scope.showQuickEntryWindow = false; // 是否显示设置快捷入口弹窗
        $scope.isShowOpenGuide = false;
        $scope.shopAddress = '/usr/api/jumpToStore?url=%2Fshop.html%3FstoreId%3D&m=NO_C&token=' + DSSX.util.getToken();
        $scope.shopDesignAddress = '/usr/api/jumpToManageStore?m=NO_C&token=' + DSSX.util.getToken();
        $scope.isPreviewMode = DSSX.attr.isPreviewMode;
        $scope.indexData = {};
        $scope.curUserIsTeacher = DSSX.currUser.isTeacher;
        $scope.shortcutSetting = {
            all: [],
            my: [],
            loading: false,
            updating: false,
            showNext: false,
            curTab: 0,
            selectTab: function (arg_index) {
                if (!(arg_index in $scope.shortcutSetting.all[$scope.shortcutSetting.curTab].child)) {
                    return;
                }

                var target = $scope.shortcutSetting.all[$scope.shortcutSetting.curTab].child[arg_index];
                if ($scope.shortcutSetting.my.length >= 5 && !target.selected) {
                    return;
                }

                target.selected = !target.selected;
                if (target.selected) {
                    $scope.shortcutSetting.my.push({
                        enUS: target.enUS,
                        menuCode: target.menuCode,
                        url: target.url,
                        zhCN: target.zhCN
                    });
                } else {
                    var index = -1;
                    $scope.shortcutSetting.my.forEach(function (v, i) {
                        if (v.menuCode === target.menuCode) {
                            index = i
                        }
                    });

                    index !== -1 && $scope.shortcutSetting.my.splice(index, 1);
                }
            },
            removeTab: function (arg_index) {
                console.log(arg_index);
                arg_index in $scope.shortcutSetting.my && function () {
                    var target = $scope.shortcutSetting.my.splice(arg_index, 1)[0];
                    $scope.shortcutSetting.all.forEach(function (value, i) {
                        value.child.forEach(function (v, j) {
                            v.menuCode === target.menuCode && ($scope.shortcutSetting.all[i].child[j].selected = false);
                        });
                    });
                }();
            }
        };
        $scope.todoList = [{
            name: '未读询盘',
            value: 0,
            href: '/message/default-all.html'
        }, {
            name: '未读RFQ',
            value: 69,
            href: '/rfq-index.html'
        }, {
            name: '待编辑报价',
            value: 0,
            href: ''
        }, {
            name: '流失预警/高潜复购',
            value: 2,
            href: ''
        }, {
            name: '近7日主账号回复',
            value: 0,
            href: ''
        }, {
            name: '可营销访客',
            value: 192,
            href: ''
        }, {
            name: '供应商诊断待优化',
            value: 0,
            href: ''
        }, {
            name: '审核不通过产品',
            value: 0,
            href: '/product-manage.html?status=noPass'
        }];
        $scope.infoCard = {
            showNext: false,
            list: [{
                name: '诚信等级',
                value: 'AA',
                href: ''
            }, {
                name: '交易等级',
                value: '6',
                href: ''
            }, {
                name: '外汇评级',
                value: 'A',
                href: ''
            }, {
                name: '本季一达通出口额',
                value: '$ 13494.0',
                href: ''
            }, {
                name: '供应链客户代码',
                value: '161537',
                href: ''
            }, {
                name: '积分',
                value: '50',
                href: ''
            }, {
                name: '本季度牌级',
                value: '银',
                href: ''
            }]
        };
        $scope.business = [];

        $scope.c = {
            // 强制显示快捷入口
            forceDisplayQuickEntry: false,
        };

        var clipboard;
        // 四种状态
        // 0 要求提交认证信息
        // 1 要求提交公司信息
        // 2 要求发布产品信息
        // 3 要求参加考试 【开通时间按钮灰色】
        // 4 考试完成等待选择开通时间  【开通时间按钮变亮】
        // 5 已选择开通时间，成功开通【首页正常显示】


        /**
         *  -- showShortMenu[false] : curStatus -> 5 【成功开通】
         *  -- showShortMenu[true]
         *            -- showProgress[true]
         *                  --  curStatus -> 0~2 [judge index]
         *            -- showTestAndTime[true]
         *                  -- curStatus -> 3、4 [finishTest?]
         */

        $scope.exitPreviewMod = function () {
            layer.confirm('退出预览模式，返回自身国际站？', {icon: 3, title: '预览模式'}, function (index) {
                if(DSSX.APP_CONFIG.dev.previewMethod && DSSX.APP_CONFIG.dev.previewMethod == 'cookie') {
                    location.href = '//' + DSSX.host.main+ '/pub/api/back2MyAlibaba';
                }else{
                    location.href = '//' + DSSX.host.main;
                }

                // layer.close(index);
            });
        };

        init();

        function init() {
            judgeStatus();

            !$scope.isPreviewMode && // 不是预览模式
            DSSX.attr.state === 'write_auth_info' && // 处在开通国际站流程第一步，还没有填写完认证信息
            DSSX.currUser.isShowAccountOpenTip && // 进行国际站状态提示 设置为打开状态
            showExpeTips();
            initShortcutSettingEvent();
            initHandlePreviewEvent();
            loadRecommendBusiness();
            loadHomePageTodo();

            $scope.indexData.curStatus !== 5 && initEvent();

            // 状态：要求参加考试 【开通时间按钮灰色】
            if (3 === $scope.indexData.curStatus) {
                initCalendarPlugin()
            }

            // 状态：考试完成等待选择开通时间
            if (4 === $scope.indexData.curStatus) {
                initCalendarPlugin()
            }

            // 去除加载过程隐藏起来的 css class
            $('.loading-hide').removeClass('loading-hide');

            $timeout(function () {
                initLeftMenu();
            });
            $scope.$evalAsync();
        }

        function judgeStatus() {
            switch (DSSX.attr.state) {
                case "write_auth_info":  //填写认证信息的状态
                    $scope.indexData.curStatus = 0;
                    break;
                case "write_cpny_info":      //填写公司信息的状态
                    $scope.indexData.curStatus = 1;
                    break;
                case "publish_first_product"://发布第一个产品的状态
                    $scope.indexData.curStatus = 2;
                    break;
                case "write_exam":           //考试
                    $scope.indexData.curStatus = 3;
                    break;
                case "select_open_date":     //选择开通日期
                    $scope.indexData.curStatus = 4;
                    break;
                case "opened":             //开通状态
                    $scope.indexData.curStatus = 5;
                    break;
            }

            //是否显示进度流程
            $scope.indexData.showProgress = $scope.indexData.curStatus <= 2;

            $scope.indexData.finishTest = $scope.indexData.curStatus === 4;

            //显示考试和开通时间板块
            $scope.indexData.showTestAndTime = $scope.indexData.curStatus === 4 || $scope.indexData.curStatus === 3;

            // true显示短菜单  false:长菜单
            $scope.indexData.showShortMenu = $scope.indexData.curStatus !== 5;

            var util = DSSX.util, TrainingMission = DSSX.TrainingMission;
            var tm_trainAction = util.queryString('tm_trainAction');
            var tm_trainTypeId = +util.queryString('tm_trainType');
            if ('view' === tm_trainAction) {
                // 如果是，当时正在查看 快捷入口 这个实训任务的答案，则显示快捷入口
                if (tm_trainTypeId === TrainingMission.getMissionTypeId(TrainingMission.MissionType.SiteManage_QuickEntry)) {
                    $scope.c.forceDisplayQuickEntry = true;
                }
            }

            $timeout(function () {
                if ($scope.indexData.curStatus === 5) {
                    $('ds-header .dh-nav > a, #nav a').each(function () {
                        var attr = $(this).attr('data-ds-href');
                        attr && $(this).attr('href', attr);
                        // console.log(attr, this);
                    });
                    $('#nav [data-ds-onclick]').each(function () {
                        var onclick = $(this).attr('data-ds-onclick');
                        onclick && $(this).attr('onclick', onclick);
                        console.log(onclick, this);
                    });
                } else {
                    $('ds-header .dh-nav > a, #nav a').each(function () {
                        var attr = $(this).attr('href');
                        $(this).attr('data-ds-href', attr).removeAttr('href');
                        // console.log(attr, this);
                    });
                    $('#nav [onclick]').each(function () {
                        var onclick = $(this).attr('onclick');
                        $(this).attr('data-ds-onclick', onclick).removeAttr('onclick');
                        console.log(onclick, this);
                    });
                }
                $scope.$apply();
            })
        }

        function showExpeTips() {
            var pending = false;
            var index = layer.open({
                title: '提示',
                closeBtn: 0,
                resize: false,
                move: false,
                skin: 'layui-layer-expe-tips',
                content: [
                    '<div class="expe-tips">',
                    '<h3>是否体验国际站开通流程</h3>',
                    '<div><label><input type="radio" name="skip" value="0" checked>体验国际站开通</label></div>',
                    '<div><label><input type="radio" name="skip" value="1">跳过体验</label></div>',
                    '<div><label><input type="checkbox" name="hide">不再提示</label></div>',
                    '</div>'
                ].join(''),
                area: ['360px', '223px'],
                btn: ['确定'],
                yes: function () {
                    if (pending) {
                        return;
                    }

                    var isSkip = !!+$('.expe-tips [name=skip]:checked').val(),
                        isHide = $('.expe-tips [name=hide]').is(':checked');
                    console.log('isSkip: ' + isSkip, 'isHide: ' + isHide);

                    if (isHide) {
                        //不在提示
                        var params = {
                            token: DSSX.util.getToken()
                        };
                        isHide && (params.openTip = 'close');
                        isSkip && (params.state = 7);//跳过体验，强制开通

                        pending = true;

                        request.get('/usr/api/updateAccountState', params, true)
                            .then(function (rs) {
                                if (isSkip) {
                                    location.href = '/';
                                }
                                layer.close(index);
                                pending = false;
                            }, function (error) {
                                DSSX.dialog.err(error, {
                                    moduleName: 'index_ng.js',
                                    funcName: 'showExpeTips',
                                    text: '更新状态失败'
                                });
                                pending = false;
                            });
                    } else {
                        if (isSkip) {
                            //跳过体验，临时开通
                            DSSX.attr.state = 'opened';
                        }
                        judgeStatus();
                        layer.close(index);
                    }
                    // layer.close(index);
                }
            });
        }

        $scope.indexData.tips = [
            {
                title: "提交认证信息",
                tip: "（参考周期：8-10个工作日）",
                titleLink: "/post-auth-info.html?noredirect=true",
                icon: "icon-tijiaogongdan",
                passTitle: "恭喜您，贵公司已经通过第三方公司认证",
                passLink: "/view-auth-info.html?type=result",
                passTip: "查看认证信息"
            },
            {
                title: "提交公司信息",
                tip: "（请先提交认证信息）",
                titleLink: "/company-msg.html",
                icon: "icon-tijiaodingdan",
                passTitle: "恭喜您，贵公司已经通过审核",
                passLink: "/company-msg.html",
                passTip: "查看完善公司信息"
            },
            {
                title: "发布产品信息",
                tip: "（请先提交认证信息）",
                titleLink: "/product-post.html?first=true&productId=" + DSSX.util.safe(DSSX, 'currUser.firstProdId', ''),
                icon: "icon-shangchuan",
                passTitle: "恭喜您，贵公司成功发布产品信息",
                passLink: "#",
                passTip: "查看发布信息"
            }
        ];

        // // 短菜单下 禁止菜单点击、禁止头部的跳转
        // if($scope.indexData.showShortMenu){
        //     $("a.win-sub-item,.sub-item-arror").removeAttr("href");
        //     $(".head-cc a").removeAttr("href");
        // }

        // 实训平台预览地址
        var layerNum;
        $scope.createLink = function () {
            if (!clipboard) {
                clipboard = initClipboard();
            }

            layerNum = layer.open({
                type: 1,
                title: '实训平台预览地址',
                area: ['400px', '160px'],
                content: $('#address-copy-success'),
                skin: 'layui-tip'
            });

            // $scope.previewLink = DSSX.host.protocol + '//' + DSSX.attr.previewModeHost;
            if(DSSX.APP_CONFIG.dev.previewMethod && DSSX.APP_CONFIG.dev.previewMethod == 'cookie'){
                $scope.previewLink = DSSX.host.protocol + '//' + DSSX.host.main+ '/pub/api/back2MyAlibaba?tag='+DSSX.attr.previewPrefix.split('.')[0];
            }else{
                $scope.previewLink = DSSX.host.protocol + '//' + DSSX.attr.previewModeHost;
            }
            // $scope.previewLink = DSSX.host.protocol + '//' + DSSX.host.main+ '/pub/api/back2MyAlibaba?tag='+DSSX.attr.previewPrefix.split('.')[0];
        };

        $scope.closePreviewLinkLayer = function () {
            layer.close(layerNum);
        };

        $scope.nextStep = function () {

            if (!$scope.indexData.finishTest) {
                return;
            }
            var openTimStr = $("#openTime").val();

            DSSX.request.get('/usr/api/updateAccountState', {state: 5, date: openTimStr}, true)
                .then(function () {
                    location.href = '//' + DSSX.attr.previewModeHost + '/index-tip.html';
                }, function (error) {
                    DSSX.dialog.err(error, {
                        moduleName: '后台首页',
                        funcName: 'nextStep',
                        text: '更新账户状态失败'
                    });
                })
            ;
        };

        $scope.showExamTip = function () {
            layer.open({
                type: 2,
                close: 1,
                title: false,
                shadeClose: true,
                shade: 0.3,
                area: ["450px", "340px"],
                content: '/exam-tip.html',
                success: function (layero, index) {
                    var frameBody = $(layer.getChildFrame('body', index));
                    frameBody.find(".btn-exam").click(function () {
                        window.location.href = "/exam.html";
                    });
                    frameBody.find(".btn-learn").click(function () {
                        window.location.href = 'https://service.alibaba.com/zq/xinshoubibei.php';
                    });
                }
            });
        };

        // 未开通国际站时左侧菜单栏绑定点击提示事件
        function initEvent() {

            // var navContainer = $('#nav');
            // navContainer.on('click', '.left-sub-item', layerAccountOpenTip);
            // navContainer.on('click', '.win-sub-item', layerAccountOpenTip);
            $('.common-sidebar-view').on('click', 'a[ng-href]', layerAccountOpenTip);
            $('.dh-nav').on('click', '.ds-index-bind-tip-event', layerAccountOpenTip);
        }

        function layerAccountOpenTip() {
            if (!$scope.indexData.showShortMenu) {
                return;
            }

            $timeout(function () {
                $scope.isShowOpenGuide = true;
            });
        }

        $scope.showQ = function () {
            $scope.showQuickEntryWindow = true;
        };

        function initCalendarPlugin() {
            var now = new Date();
            var d1 = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            var d2 = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 10);

            $('#openTime').val($filter('date')(now, 'yyyy-MM-dd'));
            laydate.render({
                elem: '#openTime',
                min: $filter('date')(d1, 'yyyy-MM-dd'),
                max: $filter('date')(d2, 'yyyy-MM-dd'),
                showBottom: false
            })
        }

        function initClipboard() {
            var clipboard = new Clipboard('.copy-btn');

            clipboard.on('success', function (e) {
                layer.close(layerNum);
                layer.msg('复制成功', {icon: 1, offset: ['100px', '50%']});
                e.clearSelection(); // 清除文字选中状态
            });

            clipboard.on('error', function (e) {
                layer.close(layerNum);
                e.clearSelection();
                layer.open({
                    type: 1,
                    title: '实训平台预览地址',
                    area: ['400px', '160px'],
                    content: $('#address-copy-fail'),
                    skin: 'layui-tip',
                    btn: ['关闭']
                });
            });

            return clipboard;
        }

        // 从旧的 index.js 迁移过来
        function initLeftMenu() {
            var itemSet = $(".sub-item-arror");
            var winSet = $(".win");
            var lastShowWinIndex = -1; // 代表上一个菜单的索引值


            // 当前鼠标所在的菜单的索引值
            var curWinIndex = 0;
            // 遍历每个一级菜单，鼠标移入则显示二级菜单，否则隐藏
            itemSet.hover(
                function () {
                    curWinIndex = 0;
                    for (; curWinIndex < itemSet.length; curWinIndex++) {
                        if (this === itemSet[curWinIndex]) {
                            break;
                        }
                    }
                    showWin(curWinIndex);
                }, function () {
                    hideWin(curWinIndex);
                });

            // 遍历每个二级菜单，如果鼠标移入则继续显示，否则隐藏
            winSet.each(function (i, val) {
                $(val).hover(
                    function () {
                        showWin(i);
                    }, function () {
                        hideWin(i);
                    });
            });

            // 打开菜单之前首先关闭上一个菜单
            function showWin(index) {
                if (lastShowWinIndex !== -1) {
                    hideWin(lastShowWinIndex);
                }
                $(winSet[index]).addClass("open-win").removeClass("close-win");
                $(itemSet[index]).addClass("sub-item-arror-hover");
                lastShowWinIndex = index;
            }

            // 通过修改样式来实现菜单的收起和打开
            function hideWin(index) {
                $(winSet[index]).addClass("close-win").removeClass("open-win");
                $(itemSet[index]).removeClass("sub-item-arror-hover");
            }
        }

        function initShortcutSettingEvent() {
            event.on('common-sidebar.shortcut-setting', function () {
                if ($scope.indexData.showShortMenu) {
                    layerAccountOpenTip();
                    return;
                }

                loadShortcutSettingData();
                var index = layer.open({
                    type: 1,
                    title: '快捷入口',
                    resize: false,
                    move: false,
                    content: $('#common-sidebar-shortcut-setting'),
                    skin: 'common-sidebar-shortcut-setting-layer',
                    area: ['800px', '500px'],
                    btnAlign: 'c',
                    btn: ['确定'],
                    end: function () {
                        $scope.shortcutSetting.curTab = 0;
                    },
                    yes: function () {
                        if ($scope.shortcutSetting.updating) {
                            return;
                        }

                        $scope.shortcutSetting.updating = true;
                        request.post('/usr/api/updateAccInfo', {
                            upQuickEntry: 'yes'
                        }, {
                            quickEntry: $scope.shortcutSetting.my.map(function (value) {
                                return value.menuCode;
                            })
                        }, true)
                        .then(callback, errCallback)
                        .then(SiteManageQuickEntryTMInterpolator)
                        .catch(function(error) {
                            console.error("[initShortcutSettingEvent] set quick entry without permission", error);
                        })
                        .finally(function () {
                            $scope.shortcutSetting.updating = false;
                        });
                        
                        function callback(res) {
                            DSSX.dialog.msg('更新成功');
                            event.emit('common-sidebar.load-quick-entry-list', {});
                            layer.close(index);
                        }
                        function errCallback(error) {
                            DSSX.dialog.err(error, {
                                moduleName: '设置我的快捷入口',
                                funcName: 'setQuickEntryList',
                                text: '设置快捷入口失败'
                            });
                            return $q.reject(error);
                        }
                        function SiteManageQuickEntryTMInterpolator() {
                            var TM = DSSX.TrainingMission;

                            return TM.tryStart(TM.MissionType.SiteManage_QuickEntry, {bindId: '', bindInfo: ''})
                                .then(tryStartTMSuc, tryStartTMErr);

                            function tryStartTMSuc(tmc) {

                            }

                            function tryStartTMErr(err) {
                                console.log('[TM] tryStartTMErr', err);
                            }
                        }
                    }
                });
            });
        }

        function loadShortcutSettingData() {
            if ($scope.shortcutSetting.loading) {
                return;
            }

            $scope.shortcutSetting.loading = true;
            loadQuickEntryList().then(loadAddedQuickEntryList).then(findAddedQuickEntry).finally(function () {
                $scope.shortcutSetting.loading = false;

                $timeout(function () {
                    Sortable.create(document.getElementById('common-sidebar-shortcut-setting-wrapper-list'), {
                        onEnd: function (evt) {
                            var target = $scope.shortcutSetting.my[evt.oldIndex];
                            $scope.shortcutSetting.my.splice(evt.oldIndex, 1);
                            $scope.shortcutSetting.my.splice(evt.newIndex, 0, target);
                        }
                    });
                });
            });
        }

        /**
         * 加载快捷入口列表
         * @returns {Promise<T | never>}
         */
        function loadQuickEntryList() {

            return request.get('/pub/api/listQuickEntry', {}, true)
                .then(callback, errCallback);

            function callback(res) {
                $scope.shortcutSetting.all = DSSX.util.safe(res, 'data.quickEntryList', []);
            }

            function errCallback(error) {
                DSSX.dialog.err(error, {
                    moduleName: '快捷入口设置',
                    funcName: 'loadQuickEntryList',
                    text: '加载快捷入口列表失败'
                });
            }

        }

        /**
         * 加载已添加的快捷列表入口
         * @returns {Promise<Array | never>}
         */
        function loadAddedQuickEntryList() {

            return request.get('/usr/api/loadAccInfo', {returnField: 'quickEntry'}, true)
                .then(callback, errCallback);

            function callback(res) {
                $scope.shortcutSetting.my = DSSX.util.safe(res, 'data.accInfo.quickEntry', []);
                return $scope.shortcutSetting.my
            }

            function errCallback(error) {
                DSSX.dialog.err(error, {
                    moduleName: '快捷入口设置',
                    funcName: 'loadAddedQuickEntryList',
                    text: '加载已添加的快捷入口列表失败'
                });
                return $q.reject(error);
            }
        }

        /**
         * 载入首页待办任务接口
         */
        function loadHomePageTodo() {
            return request.get('/usr/api/loadTodoThings', {}, true)
                .then(callback, errCallback);
            function callback(res) {
                var unread = res.data.things[1].items[0]
                $scope.todoList[0].value = unread.count
            }
            function errCallback(error) {

            }
        }

        /**
         * 在快捷入口列表中找出已添加的快捷入口
         */
        function findAddedQuickEntry() {

            var qList = $scope.shortcutSetting.all;
            var beList = $scope.shortcutSetting.my;

            for (var i = 0; i < qList.length; i++) {

                var list = qList[i].child || [];
                for (var j = 0; j < list.length; j++) {

                    list[j].selected = false;
                    for (var k = 0; k < beList.length; k++) {

                        if (list[j].menuCode === beList[k].menuCode) {
                            list[j].selected = true;
                        }
                    }
                }
            }
        }


        /**
         * 预览模式事件
         */
        function initHandlePreviewEvent() {
            event.on('common-sidebar.handle-preview', function () {
                $scope.isPreviewMode ? $scope.exitPreviewMod() : $scope.createLink();
            });
        }

        function loadRecommendBusiness() {
            var COUNTRY_FLAG_URL = '/third-part/ali-assets/flag/xx.gif';
            return request.get('/pub/api/listRecmdRfq', {}, false)
                .then(callback, errCallback);

            function callback(res) {
                var result = [];
                var bsns = DSSX.util.safe(res, 'data.rfqList', []);
                for (var i = 0; i< bsns.length; i++) {
                    var bn = {};
                    bn.subject = bsns[i].subject;
                    bn.detail = bsns[i].description;
                    bn.quantityNum = bsns[i].quantity;
                    bn.unit = 'Pieces';
                    bn.countryFlag = COUNTRY_FLAG_URL.replace("xx", bsns[i].countySimple).toLocaleLowerCase();
                    bn.country = bsns[i].country;
                    bn.time = bsns[i].openTimeStr;
                    bn.timeZone = '(U.S. PST)';
                    bn.remainNum  = bsns[i].rfqLeftCount;
                    bn.key = bsns[i].keyword === 'dress' ? 'dress' + (i + 1) : 'shoes' + (i + 1);
                    bn.rfqId = bsns[i].id;
                    result.push(bn);
                }

                $scope.business = result;
            }

            function errCallback(error) {
                DSSX.dialog.err(error, {
                    moduleName: 'RFQ市场',
                    funcName: 'loadRecommendBusiness',
                    text: '加载推荐的商机失败'
                });
            }

        }

        $scope.switchVersion = function () {
            layer.open({
                title: '',
                content: '亲爱的商家， 为了更好助力您外贸工作，“MyAlibaba工作台”进行了产品功能升级，若是体验过程中有不满意的地方点此 <a href="javascript:;">反馈原因</a>',
                area: ['600px', '140px'],
                btn: ['留在新版', '返回老版'],
                btnAlign: 'c',
                resize: false,
                move: false,
                yes: function (index) {
                    layer.close(index);
                },
                btn2: function () {
                    location.href = 'old-index.html'
                }
            });
        }
    }]);
